import { useFeatureGatingSpecialization } from "common/feature_gating/specialization";

type ViewerForSignerSpecialization = {
  user: null | {
    email: null | string;
    customerProfile: null | { id: string };
  };
};

export function useSignerFeatureGatingSpecialization(
  viewer: ViewerForSignerSpecialization | undefined,
): boolean {
  const customerProfile = viewer?.user?.customerProfile;
  return useFeatureGatingSpecialization(
    customerProfile && { key: customerProfile.id, email: viewer.user!.email },
    [/signup\/upload/, /easy-link/],
  );
}
