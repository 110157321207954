import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

import { useQuery } from "util/graphql";
import LoadingIndicator from "common/core/loading_indicator";
import { OrganizationTypeEnum } from "graphql_globals";
import App from "constants/applications";
import { redirectToSubdomain } from "util/application_redirect";
import Subdomains from "constants/app_subdomains";
import { segmentAnonymousId } from "util/segment";

import PortalGatewayQuery, { type PortalGateway_viewer_user as User } from "./index.query.graphql";

const ORG_TO_PORTAL: Readonly<
  Record<OrganizationTypeEnum, typeof App.BUSINESS | typeof App.LENDER | typeof App.TITLE_AGENCY>
> = {
  [OrganizationTypeEnum.BROKER]: App.BUSINESS,
  [OrganizationTypeEnum.BUSINESS]: App.BUSINESS,
  [OrganizationTypeEnum.REALTOR]: App.BUSINESS,
  [OrganizationTypeEnum.LENDER]: App.LENDER,
  [OrganizationTypeEnum.TITLE_AGENCY]: App.TITLE_AGENCY,
  [OrganizationTypeEnum.TITLE_UNDERWRITER]: App.BUSINESS,
};

function PortalRedirect({ user }: { user: User }) {
  const navigate = useNavigate();
  const anonymousId = segmentAnonymousId();
  const path = anonymousId ? `/?ajs_aid=${anonymousId}` : "/";
  useEffect(() => {
    const { organization } = user;
    const hasOrganizationTransactions = organization
      ? organization.organizationTransactions.totalCount > 0
      : false;
    const hasPersonalDocumentBundles = !user.personalDocumentBundles.isEmpty;
    if (user.accountTypes?.includes("ADMIN")) {
      return redirectToSubdomain(Subdomains.admin, path);
    } else if (organization && hasOrganizationTransactions) {
      // If a user's org has sent txns before, they most likely should be redirected to org
      return redirectToSubdomain(Subdomains[ORG_TO_PORTAL[organization.organizationType]], path);
    } else if (organization && !hasOrganizationTransactions && !hasPersonalDocumentBundles) {
      // This is to account for brand new org signups
      return redirectToSubdomain(Subdomains[ORG_TO_PORTAL[organization.organizationType]], path);
    } else if (user.accountTypes?.includes("CUSTOMER")) {
      return navigate("/");
    }

    const types = user.accountTypes?.join(",") || "";
    throw new Error(
      `User(${user.id}) did not meet expected accountType(${types})/organizationType(${user.organization?.organizationType}) combination`,
    );
  }, []);

  return null;
}

function PortalGateway() {
  const { data, loading } = useQuery(PortalGatewayQuery);
  return loading ? <LoadingIndicator /> : <PortalRedirect user={data!.viewer.user!} />;
}

export default PortalGateway;
