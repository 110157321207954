import { type ReactElement } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { forgotPassword } from "redux/actions/authentication";
import { useDispatch, useSelector } from "redux/util";
import Button from "common/core/button";
import WorkflowModal from "common/modals/workflow_modal";
import { useLogout } from "common/authentication";
import { pushNotification } from "common/core/notification_center/actions";

type Props = {
  children?: ReactElement;
  viewer: {
    user: {
      id: string;
      email: string | null;
    } | null;
    limitedSession: boolean;
  };
};

export default function LimitedSessionWrapper({ viewer, children }: Props) {
  const bundleMatch = useMatch({
    path: "/bundle/:documentBundleId",
    end: false,
  });
  const dashboardMatch = useMatch({
    path: "/bundle/records",
    end: false,
  });
  const meetingMatch = useMatch({
    path: "/meeting/:meetingId",
    end: false,
  });
  const esignMatch = useMatch({
    path: "/esign/:globalID",
    end: false,
  });
  const refereeMatch = useMatch({
    path: "referee-meeting/:meetingId",
    end: false,
  });

  const navigate = useNavigate();
  const logout = useLogout();
  const dispatch = useDispatch();
  const { sent, pending } = useSelector((state) => state.authentication);

  const onSendLink = async (email: string | undefined | null) => {
    if (email && !pending && !sent) {
      await dispatch(forgotPassword(email));
    }
    pushNotification({
      message: (
        <FormattedMessage
          id="76a80b0f-3115-455d-8283-3e84c2e727fd"
          defaultMessage="Check your email to set a password."
        />
      ),
    });
    logout();
  };

  if (
    viewer.limitedSession &&
    !(meetingMatch || (bundleMatch && !dashboardMatch) || esignMatch || refereeMatch)
  ) {
    return (
      <WorkflowModal
        title={
          <FormattedMessage
            id="ac962e44-6127-4a33-a3b8-461c2117640a"
            defaultMessage="You can't access this page without a password"
          />
        }
        footerSeparator={false}
        buttons={[
          <Button
            buttonColor="action"
            variant="secondary"
            key="cancel"
            onClick={() => navigate(-1)}
          >
            <FormattedMessage id="9b6a5817-f2c5-446f-98e0-9b08176fc25f" defaultMessage="Cancel" />
          </Button>,
          <Button
            buttonColor="action"
            variant="primary"
            key="link"
            onClick={() => {
              onSendLink(viewer.user?.email);
            }}
            isLoading={pending}
            disabled={pending || sent}
          >
            <FormattedMessage
              id="e0de9920-95a6-40f1-b18d-b4f5689c5bfa"
              defaultMessage="Send link"
            />
          </Button>,
        ]}
        autoFocus
        isSensitive={false}
      >
        <FormattedMessage
          id="2c80569d-a998-4403-90ce-b77f465d52b7"
          defaultMessage="To continue verify your email and set a password."
          tagName="p"
        />
      </WorkflowModal>
    );
  }

  return children || <></>;
}
