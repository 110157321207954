import { memo, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useLogout, useSingleShotAuthentication } from "common/authentication";
import { useQuery } from "util/graphql";
import LoadingIndicator from "common/core/loading_indicator";
import { SIGN_PATH } from "util/routes";

import SignerRedirectRootQuery from "./redirect_root_query.graphql";

function RedirectRoot() {
  const { loading, data } = useQuery(SignerRedirectRootQuery);
  const navigate = useNavigate();
  const isLoggedOut = useRef(false);
  const [isSingleShotWorkflowAuthenticated, setIsSingleShotWorkflowAuthenticated] =
    useSingleShotAuthentication();
  const logout = useLogout();

  useEffect(() => {
    if (loading || !data) {
      return;
    }

    if (isLoggedOut.current || isSingleShotWorkflowAuthenticated) {
      // This means the user has a "single shot" workflow and should be logged out at the root.
      setIsSingleShotWorkflowAuthenticated(false); // Clean out the state about this user
      isLoggedOut.current = true;
      logout();
      return;
    }

    navigate(SIGN_PATH, { replace: true });
  }, [
    logout,
    navigate,
    loading,
    data,
    isSingleShotWorkflowAuthenticated,
    setIsSingleShotWorkflowAuthenticated,
  ]);

  return <LoadingIndicator />;
}

export default memo(RedirectRoot);
