import { type ReactNode, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function PreMeetingRedirect({ children }: { children: ReactNode }) {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // prevent users being redirect back into pre-meeting flow from login
    if (document.referrer.includes("/login") && location.key === "default") {
      navigate("/");
    }
  }, []);

  return <>{children}</>;
}
